import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Opcao } from 'src/app/models/opcao';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModuloService {
  constructor(private http: HttpClient) {}

  // getModulosFlaschCard(plano: number) {
  //   return this.http.get<Opcao[]>(`${environment.baseApiUrl}/modulos`);
  // }

  getModulos(tipo: string = '') {
    return this.http.get<Opcao[]>(`${environment.baseApiUrl}/modulos/${tipo}`);
  }
}
