import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Opcao } from 'src/app/models/opcao';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubModuloService {
  constructor(private http: HttpClient) {}

  getSubModulos(idPlano: number, idModulo: number, tipo: string = '') {
    return this.http.get<Opcao[]>(`${environment.baseApiUrl}/SubModulos/${idPlano}/${idModulo}/${tipo}`);
  }
}
